<!--
 * @Description: 维护回访
 * @Author: ChenXueLin
 * @Date: 2022-04-25 16:28:59
 * @LastEditTime: 2022-07-15 16:20:55
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 添加任务项弹框 -->
  <el-dialog
    v-dialogDrag
    title="添加任务项"
    :visible.sync="addItemVisible"
    width="900px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
  >
    <!-- 搜索头部 start -->
    <table-title>
      <template v-slot:searchForm>
        <el-form class="search-list" ref="searchForm" :model="searchForm">
          <el-form-item class="search-item--1" prop="corpId">
            <e6-vr-select
              v-model="searchForm.corpId"
              :data="corpList"
              placeholder="客户名称"
              title="客户名称"
              virtual
              remote
              clearable
              :is-title="true"
              @filterChange="handleLoadCorpFilter"
              :props="{
                id: 'corpId',
                label: 'corpName',
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--1" prop="employeeId">
            <e6-vr-select
              v-model="searchForm.employeeId"
              :data="employeeList"
              placeholder="业务员"
              title="业务员"
              :props="{
                id: 'employeeId',
                label: 'userName',
              }"
              clearable
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--1" prop="attrIds">
            <e6-vr-select
              v-model="searchForm.attrIds"
              :data="corpAttrEnum"
              placeholder="客户属性"
              title="客户属性"
              :props="{
                id: 'id',
                label: 'attrValueDesc',
              }"
              multiple
              clearable
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--1" prop="corpClass">
            <e6-vr-select
              v-model="searchForm.corpClass"
              :data="corpClassEnum"
              placeholder="客户分级"
              title="客户分级"
              :props="{
                id: 'codeValue',
                label: 'codeName',
              }"
              clearable
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--1" prop="companyId">
            <e6-vr-select
              v-model="searchForm.companyId"
              :data="companyEnum"
              placeholder="所属主体"
              title="所属主体"
              :props="{
                id: 'companyId',
                label: 'companyFullName',
              }"
              clearable
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--1" prop="issuccess">
            <e6-vr-select
              v-model="searchForm.issuccess"
              :data="revisitStatusList"
              placeholder="回访状态"
              title="回访状态"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName',
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--1" prop="currentMonthTimeStamp">
            <el-date-picker
              style="width:200px"
              v-model="searchForm.currentMonthTimeStamp"
              type="month"
              placeholder="选择月"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item class="search-item--2" prop="recordDate">
            <e6-date-picker
              popper-class="no-atTheMoment"
              label="记录时间"
              ref="effectiveDatePicker"
              v-model="searchForm.recordDate"
              title="记录时间"
              :picker-options="pickerOptions('searchForm.recordDate')"
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder="记录时间（始）"
              end-placeholder="记录时间（止）"
            ></e6-date-picker>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button class="reset" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
      </template>
    </table-title>
    <!-- 搜索头部 end -->
    <section class="table-wrapper">
      <el-table
        border
        :data="dialogData"
        height="350"
        highlight-current-row
        @selection-change="handleSelection"
        ref="multipleTable"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in dialogColumnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </section>
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="pushData('maintenance')"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import dialogMixins from "./index";
import { printError } from "@/utils/util";

import { getMaintenanceList } from "@/api";
export default {
  name: "maintenanceVisit",
  components: {},
  data() {
    return {
      dialogColumnData: [
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center",
        },
        {
          fieldName: "companySimName",
          display: true,
          fieldLabel: "主体简称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "corpClassStr",
          display: true,
          fieldLabel: "客户级别",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "attrName",
          display: true,
          fieldLabel: "客户属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "regName",
          display: true,
          fieldLabel: "车牌号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "fixDate",
          display: true,
          fieldLabel: "维护日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "recordTime",
          display: true,
          fieldLabel: "记录日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "fixManName",
          display: true,
          fieldLabel: "维护人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "fixAddress",
          display: true,
          fieldLabel: "维护地点",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "instCodeNo",
          display: true,
          fieldLabel: "安装单编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "linkRpName",
          display: true,
          fieldLabel: "联系人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "linkRpNumber",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "checkResultStr",
          display: true,
          fieldLabel: "审核状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "fixStatus",
          display: true,
          fieldLabel: "维修前状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "solution",
          display: true,
          fieldLabel: "解决方法",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "resultDesc",
          display: true,
          fieldLabel: "维护结果",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "revisitTime",
          display: true,
          fieldLabel: "上次回访日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "visitMan",
          display: true,
          fieldLabel: "上次回访人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "revisitedManName",
          display: true,
          fieldLabel: "上次被回访人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "issuccessStr",
          display: true,
          fieldLabel: "回访状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
      ], //表头
      searchForm: {
        corpId: "", //客户名称
        attrIds: [], //客户属性
        employeeId: "", //业务员id
        issuccess: "", //回访状态
        companyId: "", //所属主体
        corpClass: "", //客户分级
        currentMonthTimeStamp: new Date().getTime(),
        recordDateEndTimeStamp: "",
        recordDateBeginTimeStamp: "",
        recordDate: [],
        pageIndex: 1,
        pageSize: 20,
      },
    };
  },
  mixins: [dialogMixins],
  props: ["visible"],
  computed: {},
  watch: {},
  methods: {
    handleSearch() {
      if (!this.searchForm.currentMonthTimeStamp) {
        this.$message.warning("请选择当前月份");
        return;
      }
      this.queryList();
    },
    //获取回访池列表
    async queryList() {
      try {
        this.dialogLoading = true;
        let res = await getMaintenanceList({
          ...this.searchForm,
          returnStateCode: 0,
        });
        this.dialogData = this.getFreezeResponse(res, {
          path: "data.array",
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords",
        });
        this.$nextTick(() => {
          this.$refs.multipleTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
/deep/.dialog-box .el-dialog__body {
  padding: 20px;
  box-sizing: border-box;
}
</style>
